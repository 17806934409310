<template>
  <div>
    <a-button
      v-if="showBtn"
      style="float: right;margin-bottom: 10px;z-index: 99999;"
      @click="viewHelper"
      :disabled="disabled"
      >填报助手</a-button
    >
    <a-button
      v-if="showBtn"
      style="float: right;margin-right: 10px;margin-bottom: 10px;z-index: 99999;"
      type="primary"
      @click="visible = true"
      :disabled="disabled"
      >新增</a-button
    >
    <a-table bordered :data-source="[...list, listAll]" :pagination="false">
      <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
              {{ index === list.length ? '合计' : index + 1 }}
          </template>
      </a-table-column>
      <a-table-column title="时间" data-index="createAt"></a-table-column>
      <a-table-column title="费用类型" data-index="feeType"></a-table-column>
      <a-table-column title="摘要" data-index="summary"></a-table-column>
      <a-table-column title="金额(元)" data-index="amount"></a-table-column>
      <a-table-column title="备注" data-index="remark"></a-table-column>
      <a-table-column v-if="showBtn" title="操作" align="center" width="100px">
        <template slot-scope="text, record, index">
          <a
            v-if="index < list.length"
            href="#"
            :class="disabled ? '' : 'danger'"
            @click.prevent="remove(index)"
            :disabled="disabled"
            >删除</a
          >
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      :visible="visible"
      title="费用使用项添加"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit" width="640px">
        <a-form-item label="时间">
          <a-date-picker
              valueFormat="YYYY-MM-DD HH:mm:ss"
              style="width: 100%;"
              v-decorator="[
              'createAt',
              {
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="费用类型">
          <a-select
            v-decorator="[
                'feeType',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
            <a-select-option
              v-for="item in feeTypeList"
              :key="item.id"
              :value="item.code + ' ' + item.name"
              >{{ item.code }} {{ item.name }}</a-select-option>
            </a-select>
        </a-form-item>

        <a-form-item label="摘要">
          <a-input
            v-decorator="[
              'summary',
              {
                rules: [{ message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="金额(元)">
          <a-input-number
            :min="0"
            v-decorator="[
              'amount',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-input
            v-decorator="[
              'remark',
              {
                rules: [{ message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
import request from "@/api/request";
function getFeeType() {
  return request({
    url: "/office-service/quality/constant/queryList/fee_type",
  });
}
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      feeTypeList: []
    };
  },

  computed: {
    listAll() {
      let amount = 0;
      this.list.forEach(item => {
          if (typeof item.amount === 'number') {
            amount += item.amount;
          }
      });
      return {
        amount,
      }
    },
  },

  mounted() {
    getFeeType().then(res => {
      this.feeTypeList = res;
    });
  },  
  methods: {
    viewHelper() {
      window.open("https://qiniu.njszy.com/linkcloud/production/%E8%B4%B9%E7%94%A8%E4%BD%BF%E7%94%A8%E8%A1%A8.pdf");
    },
    cancel() {
      this.visible = false;
      this.form.resetFields();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.list.push(values);
          this.cancel();
        }
      });
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #999;
  span {
    padding-left: 0.5em;
  }
}
</style>